import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainLayout from './components/general/MainLayout';
import Home from './pages/home/Home';
import Rooms from './pages/rooms/Rooms';
import { GlobalProvider } from './context/globalContext';
import About from './pages/about/About';
import Tourism from './pages/tourism/Tourism';
import './i18n';


const App: React.FC = () => {
  return (
    <GlobalProvider>
      <Router>
        <MainLayout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/rooms" element={<Rooms />} />
            <Route path="/tourism" element={<Tourism />} />
            <Route path="/contact" element={<div></div>} />
          </Routes>
        </MainLayout>
      </Router>
    </GlobalProvider>
  );
};

export default App;
