import { Link, NavLink, useLocation } from 'react-router-dom';
import Button from './Button';
import LogoIcon from '../icons/LogoIcon';
import i18n from '../../i18n';
import { t } from 'i18next';
import { useState } from 'react';
import { classNames } from '../../helpers/strings';


const langOptions = [
  {
    lang: 'fr',
    img: '/images/france.webp',
    alt: 'French',
  },
  {
    lang: 'en',
    img: '/images/england.webp',
    alt: 'English',
  },
  {
    lang: 'es',
    img: '/images/spain.webp',
    alt: 'Spanish',
  }
];
const navItems = [
  {
    to: '/about',
    text: 'headerNosotros',
  },
  {
    to: '/rooms',
    text: 'headerHabitaciones',
  },
  {
    to: '/reservations',
    text: 'headerReservas',
  },
  {
    to: '/tourism',
    text: 'headerTurismo',
  },
  {
    to: '/contact',
    text: 'headerContactos',
  }  
];

const Header = () => {
  const location = useLocation();
  const isActive = (path: string) => location.pathname === path;
  const [lang, setLang] = useState(i18n.language);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setLang(lng);
  };

  return (
    <header className='fixed w-full flex flex-col font-[Degular] tracking-wider z-50'>
      <div className="flex items-center gap-[0.833vw] justify-end max-lg:pr-[2vw] pr-[7.5vw] py-[1.12vw] bg-white">
        {langOptions.map((option) => <Button key={option.alt} customAppearance onClick={() => changeLanguage(option.lang)}>
          <img
            src={option.img}
            alt={option.alt}
            className={classNames('h-auto max-lg:w-10 w-[2.92vw] m-[0.21vw] shadow-md', lang === option.lang ? 'border-[0.156vw] border-primary' : '')}
          />
        </Button>)}
      </div>
      {/* <div className="relative bg-primary flex justify-between items-center w-full h-[100px] max-xl:pl-[3vw] pl-[7.5vw] max-xl:pr-[4vw] pr-[10vw]"> */}
      <div className={classNames("relative flex justify-between items-center w-full h-[100px] pr-[7vw] -mt-[1px] mb-[1px]", isActive('/') ? 'bg-white border-b-4 border-primary child:child:text-primary' : 'bg-primary')}>
        <Link to='/' className="flex justify-end items-center w-[21vw] max-xl:min-w-[1vw] pr-2" onClick={() => setIsMenuOpen(false)}>
          <LogoIcon color={isActive('/') ? '#B39180' : 'white'} height='5vh' width='240px' />
        </Link>
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="text-white focus:outline-none md:hidden"
        >
          {/* Hamburger Icon */}
          <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            {isMenuOpen ? (
              // Close icon
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            ) : (
              // Hamburger icon
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 8h16M4 16h16" />
            )}
          </svg>
        </button>
        <nav className={classNames('flex flex-1 max-md:absolute max-md:top-[99%] max-md:left-0 max-md:right-0 h-full items-center', isMenuOpen ? 'block' : 'max-md:hidden')}>
          <ul className="flex max-md:flex-col max-md:gap-4 max-md:py-4 text-white items-center w-full h-full justify-between text-lg max-xl:text-sm uppercase child:h-full child:flex child:child:px-6 ">
            {navItems.map((item) => (
              <li key={item.text} className='flex-1'>
                <NavLink
                  to={item.to}
                  className={classNames('h-full flex flex-1 items-center justify-center', isActive(item.to) ? 'bg-[#e7d8c9] text-primary' : '')}
                  onClick={() => setIsMenuOpen(false)}
                >
                  {t(item.text)}
                </NavLink>
              </li>
            ))}
            {/* <li className='flex-1'>
              <NavLink
                to="/about"
                className={classNames('h-full flex flex-1 items-center w', isActive('/about') ? 'bg-[#e7d8c9]' : '')}
                onClick={() => setIsMenuOpen(false)}
              >
                {t('headerNosotros')}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/rooms"
                className={classNames('h-full flex items-center', isActive('/rooms') ? 'bg-[#e7d8c9]' : '')}
                onClick={() => setIsMenuOpen(false)}
              >
                {t('headerHabitaciones')}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/reservations"
                className={classNames('h-full flex items-center', isActive('/reservations') ? 'bg-[#e7d8c9]' : '')}
                onClick={() => setIsMenuOpen(false)}
              >
                {t('headerReservas')}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/tourism"
                className={classNames('h-full flex items-center', isActive('/tourism') ? 'bg-[#e7d8c9]' : '')}
                onClick={() => setIsMenuOpen(false)}
              >
                {t('headerTurismo')}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                className={classNames('h-full flex items-center', isActive('/contact') ? 'bg-[#e7d8c9]' : '')}
                onClick={() => setIsMenuOpen(false)}
              >
                {t('headerContactos')}
              </NavLink>
            </li> */}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
