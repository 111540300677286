import React from 'react';
import Header from './Header';
import Footer from './Footer';

type MainLayoutProps = {
    children: React.ReactNode;
}

const MainLayout = ({ children }: MainLayoutProps) => {
  return (
    <>
      <Header />
      <main className='pt-[9.5vw]'>{children}</main>
      <Footer />
    </>
  );
};

export default MainLayout;
