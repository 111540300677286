import { IconProps } from '.';

const StarIcon = ({
    width = '48',
    height = '48',
    className,
    color,
}: IconProps) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} className={className} viewBox="0 0 48 48">
            <path d="M24 34.54 36.36 42l-3.27-14.06L44 18.49l-14.38-1.24L24 4l-5.62 13.25L4 18.49l10.91 9.45L11.64 42z"></path>
            <path fill="none" d="M0 0h48v48H0z"></path>
        </svg>
    )
};

export default StarIcon;
