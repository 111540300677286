import { Link } from 'react-router-dom';
import LogoIcon from '../icons/LogoIcon';
import FooterBackgroundIcon from '../icons/FooterBackgroundIcon';

const Footer = () => {

  return (
    <div className='flex flex-col h-[80vh]'>
      <FooterBackgroundIcon width='100%' />
      <div className='flex justify-center items-center flex-1 bg-background-primary'>
        <div className='flex flex-col gap-4 w-[600px] h-[200px]'>
          <div className='flex justify-center items-center'>
            <LogoIcon color='#B39180' width='284px' height='85px' />
          </div>
          <div className='flex max-lg:flex-col h-full text-lg text-primary leading-5'>
            <div className='flex flex-col justify-center flex-1 border-t border-b lg:border-r border-primary p-5 hover:child:underline'>
              <div>+33 06 444 116 164</div>
              <Link target='_blank' to='https://www.instagram.com/lescharmes_dupetitpre' >@lescharmes_dupetitpre</Link>
              <Link target='_blank' to='https://www.lescharmesdupetitpre.fr' >www.lescharmesdupetitpre.fr</Link>
            </div>
            <Link to='https://maps.app.goo.gl/dnM2Y4496SAibEUy5' target='_blank' className='flex flex-col justify-center flex-1 border-t border-b border-primary p-5 hover:underline'>
              <div>160 Rue</div>
              <div>Principale 62380</div>
              <div>Coulomby / France</div>
            </Link>
          </div>
        </div>
      </div>
      <FooterBackgroundIcon width='100%' />
    </div>
  );
};

export default Footer;
