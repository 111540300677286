import { Trans, useTranslation } from 'react-i18next';

const Tourism = () => {
    const { t } = useTranslation();

    const places = t('tourism.places', { returnObjects: true }) as { title: string, description: string, description2: string, image: string, imageDetail: string }[];
    const lastPlace = places.pop();

    return (
        <div>
            <div className="ah-[80vh] flex flex-col justify-center items-center font-[Degular] px-36 text-primary max-2xl:px-16">
                <div className="text-5xl max-xl:text-3xl font-semibold pt-12 pb-3 mb-8 w-full border-b-2 border-primary"><Trans i18nKey='tourism.title' /></div>
                <img className="w-full h-full rounded-xl overflow-hidden" src="/images/tourism_1.webp" alt="Home" style={{
                    objectFit: 'cover',
                }} />
            </div>
            {places.map((place, index) => <div key={index} className="py-40 px-28">
                <div className="bg-background-primary flex">
                    <div className="relative flex-1 flex flex-col font-[Degular] px-11 py-8 text-primary rounded-xl rounded-r-none">
                        <div className="text-5xl max-xl:text-xl font-semibold pb-3 mb-4 w-full border-b-2 border-primary"><Trans i18nKey={`tourism.places.${index}.title`} /></div>
                        {/* <div className="text-2xl text-justify whitespace-pre-line"><ul><Trans i18nKey={`tourism.places.${index}.description`}/></ul></div> */}
                        <div className="text-2xl text-justify whitespace-pre-line"><ul className="list-disc list-inside" dangerouslySetInnerHTML={{ __html: place.description }} /></div>
                        <div className="absolute left-11 bottom-8 text-2xl">{place.imageDetail}</div>
                    </div>
                    <img className="flex-[2] w-full h-full rounded-xl rounded-l-none overflow-hidden" src={place.image} width={'100%'} alt="Home" style={{
                        objectFit: 'cover',
                    }} />
                </div>
            </div>)}
            {lastPlace && <div key={places.length + 1} className="py-40 px-28">
                <div className="bg-background-primary flex">
                    <div className="relative flex-[2] flex flex-col font-[Degular] px-11 py-8 text-primary">
                        <div className="text-5xl max-xl:text-xl font-semibold pb-3 mb-4 w-full border-b-2 border-primary"><Trans i18nKey={`tourism.places.${places.length}.title`} /></div>
                        {/* <div className="text-2xl text-justify whitespace-pre-line"><ul><Trans i18nKey={`tourism.places.${index}.description`}/></ul></div> */}
                        {/* <div className="child:grid child:grid-cols-2 text-2xl text-justify whitespace-pre-line"><ul className="list-disc list-inside" dangerouslySetInnerHTML={{__html: lastPlace.description}}/></div> */}
                        <div className="flex gap-6 text-2xl text-justify whitespace-pre-line">
                            <div className="list-disc list-inside" dangerouslySetInnerHTML={{ __html: lastPlace.description }} />
                            <div className="list-disc list-inside" dangerouslySetInnerHTML={{ __html: lastPlace.description2 }} />
                        </div>
                        <div className="absolute left-11 bottom-8 text-2xl">{lastPlace.imageDetail}</div>
                    </div>
                    <img className="flex-[1] w-full rounded-xl rounded-l-none overflow-hidden" src={lastPlace.image} alt="Home" style={{
                        objectFit: 'cover',
                    }} />
                </div>
            </div>}
        </div>
    );
};

export default Tourism;
