import { useTranslation } from "react-i18next";
import Button from "../../components/general/Button";
import PDFIcon from "../../components/icons/PDFIcon";
import StarIcon from "../../components/icons/StarIcon";

const About = () => {
  const { t } = useTranslation();
  const images = [
    {
      src: '/images/home2.webp',
      alt: 'Home 2',
    },
    {
      src: '/images/home3.webp',
      alt: 'Home 3',
    },
    {
      src: '/images/home4.webp',
      alt: 'Home 4',
    },
  ];
  const specs = [
    {
      title: t('aboutSpecs.cocinaEquipada.title'),
      items: t('aboutSpecs.cocinaEquipada.items', { returnObjects: true }) as string[],
    },
    {
      title: t('aboutSpecs.tocador.title'),
      items: t('aboutSpecs.tocador.items', { returnObjects: true }) as string[],
    },
    {
      title: t('aboutSpecs.permanecer.title'),
      items: t('aboutSpecs.permanecer.items', { returnObjects: true }) as string[],
    },
    {
      title: t('aboutSpecs.habitacionDoble.title'),
      items: t('aboutSpecs.habitacionDoble.items', { returnObjects: true }) as string[],
    },
  ];

  return (
    <div>
      <div className="flex max-xl:flex-col items-center min-h-[48vh] max-xl:px-12 px-28 my-32 font-[Degular] text-2xl text-primary">
        <div className="overflow-hidden flex-1 min-h-[100%] flex justify-end">
          <img src="/images/home.webp" alt="Front" className="aspect-square h-full rounded-l-md" style={{ objectFit: "cover" }} />
        </div>
        <div className="flex flex-col items-center gap-8 min-h-32 px-10 pt-16 h-full relative flex-[2] bg-background-primary rounded-r-md">
          <div className="text-5xl w-full border-b border-primary font-semibold tracking-wider">{t('about.welcomeTitle')}</div>
          <div className="columns-2 text-justify h-full overflow-y-scroll">
            {t('about.welcomeDescription')}
          </div>
          <Button customAppearance className="p-4 py-2 w-fit border-y-4 border-text-primary text-3xl relative bottom-6 self-end uppercase">{t('about.viewGalleryButton')}</Button>
        </div>
      </div>
      <div className="max-xl:px-12 px-28 my-32">
        <div className="flex flex-col items-center h-auto p-10 max-xl:px-4 font-[Degular] text-2xl text-primary gap-6 bg-background-primary rounded-xl overflow-hidden">
          <div className="relative text-5xl max-xl:text-3xl w-full font-semibold tracking-wider">
            <span dangerouslySetInnerHTML={{ __html: t('about.descriptionTitle') }} />
            <StarIcon color="#B39180" className="ml-4 inline-block" width="25" height="25" />
            <StarIcon color="#B39180" className="inline-block" width="25" height="25" />
            <StarIcon color="#B39180" className="inline-block" width="25" height="25" />
          </div>
          <div className="flex max-xl:flex-col gap-10 w-full">
            <div className="flex-[9] grid grid-cols-2 grid-rows-2 max-xl:grid-cols-1 max-xl:grid-rows-1 gap-10 text-justify h-full min-h-36">
              {specs.map((s) => <div key={s.title} className="text-2xl max-xl:[&>li]:text-sm">
                <div className="border-b-2 border-primary uppercase text-3xl max-lg:text-xl mb-4 font-semibold">{s.title}</div>
                {s.items.map((i) => <li key={i}>{i}</li>)}
              </div>)}
            </div>
            <div className="flex-[5] flex flex-col gap-8">
              <div className="p-5 max-xl:px-2 bg-background-secondary flex gap-5 text-2xl rounded">
                <div>
                  <div className="border-[3px] border-primary rounded-full px-6 mina-w-6 mina-h-6 w-full aspect-square flex justify-center items-center font-bold text-5xl">!</div>
                </div>
                <div><span dangerouslySetInnerHTML={{ __html: t('about.bedReadyNotice') }} /></div>
              </div>
              <div className="p-5 max-xl:px-2 bg-background-secondary flex gap-5 text-2xl rounded">
                <div>
                  <PDFIcon width="100%" height="70" color="#B39180" />
                </div>
                {t('about.downloadPdfButton')}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="max-xl:px-12 px-36 pt-10">
        <div className="pb-2 border-b-2 border-primary text-6xl font-[Degular] font-medium text-primary">{t('about.fotos')}</div>
        <div className="my-8 overflow-hidden rounded-lg">
          <img src={images[0]?.src} alt={images[0]?.alt} />
        </div>
        <div className="flex gap-6 mb-10">
          <div className="flex-1 my-6 overflow-hidden rounded-lg aspect-square h-max max-xl:hidden">
            <img src={images[1]?.src} alt={images[1]?.alt} style={{ translate: "0px -350px" }} />
          </div>
          <div className="flex-1 my-6 overflow-hidden rounded-lg aspect-square h-max xl:hidden">
            <img src={images[1]?.src} alt={images[1]?.alt} style={{ translate: "0px -175px" }} />
          </div>
          <div className="flex-1 my-6 overflow-hidden rounded-lg aspect-square h-max">
            <img src={images[2]?.src} alt={images[2]?.alt} style={{ translate: "0px -170px" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
