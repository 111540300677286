import React from 'react';

interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
  customAppearance?: boolean;
}

const Button = ({ children, onClick, customAppearance, className }: ButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={customAppearance ? className : "px-4 py-2 bg-primary text-white rounded hover:bg-primary-light transition"}
    >
      {children}
    </button>
  );
};

export default Button;
