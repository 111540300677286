import Button from "../../components/general/Button";
import { Trans, useTranslation } from 'react-i18next';

const Home = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="h-[80vh] relative flex justify-center items-center">
        <img className="absolute w-full h-full" src="/images/home.webp" alt="Home" style={{
          objectFit: 'cover',
        }} />
        <div className="bg-black/25 absolute w-full h-full z-10" />
        <div className="z-10 text-text-primary flex flex-col text-center font-[Degular] items-center gap-8 font-semibold tracking-wider">
          <div className="text-5xl"><Trans i18nKey="homeSomos" components={{ linebreak: <br />}} lang="es" /></div>
          <Button customAppearance className="p-4 py-2 w-fit border-y-4 border-text-primary text-3xl uppercase">{t('homeReservar')}</Button>
        </div>
      </div>
    </div>
  );
};

export default Home;
