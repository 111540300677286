import React from 'react';
import Button from '../../components/general/Button';
import { useTranslation } from "react-i18next";

const Rooms: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="min-h-[calc(100vh-9.05vw)] bg-[#e6d7ce] w-full flex items-center justify-center font-[Degular] text-2xl text-primary">
            <img className="absolute w-full h-full z-0" src="/images/home.webp" alt="Home" style={{
                objectFit: 'cover',
            }} />
            <div className="relative mx-[5.8vw] max-lg:mx-[2.5vw] bg-white shadow-lg rounded-lg overflow-hidden flex contaainer">
                <div className="flex-[2]">
                    <img src="/images/chambre-nuptiale-9.webp" alt="Room" className="w-full h-full object-cover" />
                </div>
                <div className="flex-[3] p-8 flex flex-col">
                    <div className="text-5xl w-full border-b border-primary font-semibold tracking-wider pb-1 mb-6">{t('rooms.title')}</div>
                    <div className="columns-2 text-justify overflow-y-scroll flex-1">
                        <div dangerouslySetInnerHTML={{ __html: t('rooms.description') }} />
                        {/* <p>{t('rooms.description')}</p> */}
                        {/* <p>{t('rooms.additionalInfo')}</p>
                        <p>{t('rooms.breakfastNote')}</p>
                        <p>{t('rooms.facilitiesNote')}</p> */}
                    </div>
                    <Button customAppearance className="p-4 py-2 w-fit border-y-4 border-text-primary text-3xl relative bottom-6 self-end uppercase">
                        {t('rooms.viewRoomsButton')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Rooms;