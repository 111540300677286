import { IconProps } from '.';

const PDFIcon = ({
    width = '512',
    height = '512',
    className,
    color,
}: IconProps) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={width} height={height} fill={color} className={className}>
            <path d="M347.423,14.92c-1.745-1.867-4.183-2.92-6.736-2.92H149.553c-30.595,0-55.491,24.896-55.491,55.483v164.765H39.911    c-9.988,0-18.108,8.128-18.108,18.092v162.024c0,9.965,8.121,18.093,18.108,18.093h54.151v14.059    c0,30.587,24.896,55.483,55.491,55.483h285.154c30.593,0,55.491-24.896,55.491-55.483V170.442c0-2.348-0.896-4.606-2.514-6.323    L347.423,14.92z M349.787,44.417L459.501,161.14h-88.136c-11.899,0-21.578-9.694-21.578-21.585V44.417z M39.911,250.732    l357.074-0.392v161.633l-356.683,0.391L39.911,250.732z M434.706,481.516H149.553c-20.396,0-36.992-16.588-36.992-36.999v-14.059    h284.815c9.988,0,18.108-8.128,18.108-18.093V250.34c0-9.964-8.121-18.092-18.108-18.092H112.561V67.483    c0-20.411,16.596-36.999,36.992-36.999h181.735v109.07c0,22.097,17.979,40.07,40.077,40.07h100.331v264.893    C471.697,464.928,455.102,481.516,434.706,481.516z"/>
            <path d="M164.356 286.346c-4.304-1.837-9.091-2.77-14.359-2.77h-25.258c-1.535 0-2.83.422-3.823 1.295-.994.873-1.476 1.896-1.476 3.101v85.738c0 1.535.723 2.679 2.138 3.432 1.415.723 2.98 1.084 4.726 1.084 1.717 0 3.282-.361 4.726-1.084 1.415-.753 2.138-1.897 2.138-3.432v-32.001h16.046c5.448 0 10.356-.933 14.75-2.859 4.426-1.867 7.919-4.938 10.507-9.092 2.589-4.215 3.883-9.754 3.883-16.678v-1.024c0-6.895-1.265-12.464-3.762-16.619C172.093 291.222 168.691 288.213 164.356 286.346zM164.627 314.012c0 5.69-1.354 9.905-4.094 12.674-2.709 2.769-6.472 4.154-11.318 4.154h-16.046v-35.102h16.046c4.847 0 8.609 1.384 11.318 4.094 2.74 2.709 4.094 6.924 4.094 12.614V314.012zM233.958 286.406c-4.454-1.866-9.392-2.83-14.841-2.83h-22.91c-1.806 0-3.282.422-4.395 1.295-1.144.873-1.687 1.896-1.687 3.101v85.858c0 1.205.543 2.228 1.687 3.101 1.113.873 2.589 1.295 4.395 1.295h22.91c5.449 0 10.387-.964 14.841-2.83 4.426-1.927 7.917-4.998 10.476-9.212 2.56-4.215 3.823-9.844 3.823-16.829v-36.908c0-6.985-1.263-12.614-3.823-16.829C241.876 291.404 238.384 288.332 233.958 286.406zM234.531 349.355c0 5.69-1.355 9.905-4.064 12.614-2.739 2.71-6.503 4.094-11.35 4.094h-15.264v-70.324h15.264c4.847 0 8.611 1.384 11.35 4.094 2.709 2.709 4.064 6.924 4.064 12.614V349.355zM315.874 283.576h-45.459c-1.474 0-2.859.391-4.154 1.174-1.294.753-1.927 1.896-1.927 3.342v85.617c0 1.535.722 2.679 2.138 3.432 1.413.723 2.98 1.084 4.725 1.084 1.717 0 3.282-.361 4.728-1.084 1.415-.753 2.136-1.897 2.136-3.432v-37.45h20.051c1.565 0 2.679-.572 3.371-1.746.693-1.144 1.053-2.348 1.053-3.553 0-1.445-.391-2.77-1.113-3.884-.723-1.114-1.837-1.656-3.312-1.656h-20.051v-29.683h37.812c1.445 0 2.559-.602 3.282-1.867.753-1.265 1.114-2.709 1.114-4.335 0-1.385-.361-2.709-1.055-4.034C318.524 284.238 317.41 283.576 315.874 283.576z"/>
        </svg>
    )
};

export default PDFIcon;
